import React from 'react';
import ToolList, { Tool } from '../components/tool-list';

const tools: Tool[] = [
    // {
    //     id: 'c77e7abdc43d4342869b519e7ffba6e1',
    //     name: 'PTO Calculator',
    //     description: 'Helps calculate your paid time off (PTO).',
    //     imageUrl:
    //     'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    // },
];


const ToolsPage = () => {
    return (
        <div className="container mx-auto">
            <ToolList tools={tools}/>
            {/* <h1 className="text-3xl font-bold underline">
                Hello world!
            </h1> */}
        </div>
    );
};

export default ToolsPage;
