import React from 'react';
import SimpleCentered from '../components/simple-centered';

const HomePage = () => {
    return (
        <div className="container mx-auto">
            <SimpleCentered/>
        </div>
    );
};

export default HomePage;
